import axios from "axios";
import { getBearerTokenFromCookie } from "./helpers";
// Create an Axios instance
const axiosInstance = axios.create();

// Add an interceptor to include the access token in the request headers
axiosInstance.interceptors.request.use(
  function (config) {
    // Retrieve the access token from the cookie
    const token = getBearerTokenFromCookie();

    // Add the access token to the request headers
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosInstance;
