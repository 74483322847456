import React, { useEffect, useState } from "react";
import Button from "./button";
import Select from "react-select";
import { connect } from "react-redux";
import { startLoading, stopLoading } from "../redux/actions/appActions";
import { useForm, Controller } from "react-hook-form";
import Slider from "@mui/material/Slider";
import CardBuy from "./cardBuy";
import {
  parseAndFilterKmCompteur,
  sliderLabelText,
  isDev,
  parseAndFilterPrixTTC,
} from "../shared/helpers";
import * as yup from "yup";
import { radioUncheck } from "../shared/helpers";
import { yupResolver } from "@hookform/resolvers/yup";

import axiosInstance from "../shared/axiosInstance";
import setAccessToekn from "../shared/setAccessToken";
import { AxiosError } from "axios";

const schema = yup.object().shape({
  // firstName: yup.string().required(),
  // lastName: yup.string().required(),
  marque: yup.object().required(),
  prix: yup
    .mixed()
    .test("Doit être un nombre", "Doit être un nombre", function (value) {
      if (value === "") {
        return true; // Empty string is valid
      }
      return yup.number().isValidSync(value); // Validate as number
    }),
  mileage: yup
    .mixed()
    .test("Doit être un nombre", "Doit être un nombre", function (value) {
      if (value === "") {
        return true; // Empty string is valid
      }

      return yup.number().isValidSync(value); // Validate as number
    }),
});

const FormBuy = ({
  orange,
  formData,
  handleFormData,
  marqueOptions,
  modeleOptions = {},
  carburantOptions,
  gearboxesOptions,
  dispatch,
}: {
  orange?: boolean;
  formData?: any;
  handleFormData?: any;
  marqueOptions?: Array<string>;
  modeleOptions?: any;
  carburantOptions?: any;
  gearboxesOptions?: any;
  state?: any;
  dispatch?: any;
}) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const watchMarque = watch("marque");
  const watchModele = watch("model");

  const [isShown, setIsShown] = useState(false);
  const [modeleInnerOptions, setModuleInnerOptions] = useState<any>(null);
  const [carburantsInnerOptions, setCarburantsInnerOptions] =
    useState<any>(null);
  const [gearBoxInnerOptions, setGearBoxInnerOptions] = useState<any>(null);
  useEffect(() => {
    // radio uncheck
    radioUncheck(getValues, setValue);
  }, [watch]);

  useEffect(() => {
    setValue("model", null);
    if (watchMarque) {
      setModuleInnerOptions(modeleOptions[watchMarque.value]?.modeles || []);
    }
  }, [watchMarque]);

  useEffect(() => {
    setValue("fuel", null);
    setValue("gearbox", null);
    if (watchModele) {
      setCarburantsInnerOptions(
        carburantOptions[watchModele.value].carburants || []
      );
      setGearBoxInnerOptions(
        gearboxesOptions[watchModele.value].gearboxes || []
      );
    }
  }, [watchModele]);

  useEffect(() => {
    if (formData) {
      dispatch(startLoading());
      setValue("marque", formData.marque);
      setValue("mileage", formData.miles?.value);
      setValue("monthly", formData.monthly);

      let searchQuery = `SELECT+Id,Name,Version__c,PrixTTC__c,LoyerMensuel__c,KmCompteur__c,Marque__c,Modele__c+FROM+Vehicule__c+WHERE+Marque__c+LIKE+'%25${
        formData.marque.value
      }%25'${!isDev ? "+AND+Website_uploading__c+=+true" : ""}`;

      if (formData?.monthly) {
        if (formData.monthly[0] !== 0) {
          searchQuery += `+AND+LoyerMensuel__c+%3E%3D+${formData.monthly[0]}`;
        }
        if (formData.monthly[1] !== 1000) {
          searchQuery += `+AND+LoyerMensuel__c+%3C%3D+${formData.monthly[1]}`;
        }
      }

      const fetchProducts = async () => {
        try {
          const { data } = await axiosInstance.get(
            `/services/data/v56.0/query?q=${searchQuery}`
          );

          data.records = data.records.filter((rec) => rec.PrixTTC__c !== null);
          if (formData?.miles?.value) {
            parseAndFilterKmCompteur(data.records, formData.miles.value);
          }
          handleFormData(data.records);
        } catch (err) {
          console.log("Error:", (err as Error).message);
          if ((err as AxiosError)?.response?.status === 401) {
            await setAccessToekn();
            fetchProducts();
          }
        }
        dispatch(stopLoading());
      };
      fetchProducts();
    }
  }, []);

  const onSubmit = (sdata: any) => {
    dispatch(startLoading());
    let searchQuery =
      "SELECT+Id,Name,Version__c,PrixTTC__c,LoyerMensuel__c,KmCompteur__c,Marque__c,Modele__c+FROM+Vehicule__c+WHERE";
    if (sdata.marque) {
      searchQuery += `+Marque__c+LIKE+'%25${sdata.marque.value}%25'`;
    }
    if (sdata.model) {
      searchQuery += `+AND+Modele__c+LIKE+'%25${sdata.model.value}%25'`;
    }
    if (sdata.fuel) {
      searchQuery += `+AND+Energie__c+LIKE+'%25${sdata.fuel.value}%25'`;
    }
    if (sdata.gearbox) {
      searchQuery += `+AND+TypeBoite__c+LIKE+'%25${sdata.gearbox.value}%25'`;
    }
    if (sdata.monthly) {
      if (sdata.monthly[0] !== 0) {
        searchQuery += `+AND+LoyerMensuel__c+%3E%3D+${sdata.monthly[0]}`;
      }
      if (sdata.monthly[1] !== 1000) {
        searchQuery += `+AND+LoyerMensuel__c+%3C%3D+${sdata.monthly[1]}`;
      }
    }

    !isDev ? (searchQuery += "+AND+Website_uploading__c+=+true") : "";

    const fetchProducts = async () => {
      try {
        const { data } = await axiosInstance.get(
          `/services/data/v56.0/query?q=${searchQuery}`
        );
        let newData = data.records;

        if (sdata?.mileage) {
          parseAndFilterKmCompteur(data.records, sdata.mileage);
        }

        if (sdata?.prix) {
          newData = parseAndFilterPrixTTC(newData, sdata.prix);
        }

        handleFormData(newData);
        dispatch(stopLoading());
      } catch (err) {
        console.log("Error:", (err as Error).message);
        if ((err as AxiosError)?.response?.status === 401) {
          await setAccessToekn();
          fetchProducts();
        }
      }
    };
    fetchProducts();
  };
  return (
    <div className={`form-buy-2${isShown ? " show" : ""}`}>
      <CardBuy
        type="title"
        title="Affiner"
        description="votre recherche"
        orange={orange}
        onPress={() => setIsShown(!isShown)}
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="form form-buy form-buy-2"
        id="form-buy"
      >
        <div className={`form-content-wrapper`}>
          <div className="form-controllers">
            <div className="form-control-wrapper">
              <label className="form-label" htmlFor="monthly">
                Mensualité
              </label>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={[0, 1000]}
                render={({ field: { onChange, value, ref } }) => (
                  <div
                    className="form-control form-control-slider"
                    id="monthly"
                    ref={ref}
                  >
                    <Slider
                      // {...register("monthly")}
                      getAriaLabel={() => "Budget"}
                      value={value}
                      min={0}
                      max={1000}
                      step={100}
                      onChange={(e, newValue) => {
                        onChange(e);
                        setValue("monthly", newValue, { shouldValidate: true });
                      }}
                      size="small"
                      valueLabelDisplay="on"
                      valueLabelFormat={(v) => sliderLabelText(v, "€")}
                    />
                  </div>
                )}
                name="monthly"
              />

              {errors["monthly"]?.message && (
                <p className="error">
                  {errors["monthly"] &&
                    (errors["monthly"] as { message: string }).message}
                </p>
              )}
            </div>
            {/*
            <div className="form-control-wrapper">
              <label className="form-label" htmlFor="city">
                Ville
              </label>
              <input
                className="form-control"
                placeholder="Lille"
                {...register("city")}
              />
              {errors["city"]?.message && (
                <p className="error">
                  {errors["city"] &&
                    (errors["city"] as { message: string }).message}
                </p>
              )}
            </div>
              */}
            <div className="form-control-wrapper">
              <label className="form-label" htmlFor="marque">
                Marque
              </label>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    {...register("marque")}
                    className="form-control form-control-select"
                    options={marqueOptions}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    placeholder="Choisissez la marque"
                    id="marque"
                  />
                )}
                name="marque"
              />

              {errors["marque"]?.message && (
                <p className="error">
                  {errors["marque"] &&
                    (
                      errors["marque"] as {
                        message: string;
                      }
                    ).message}
                </p>
              )}
            </div>
            {modeleInnerOptions && (
              <div className="form-control-wrapper">
                <label className="form-label" htmlFor="model">
                  Modèle
                </label>

                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      {...register("model")}
                      className="form-control form-control-select"
                      options={modeleInnerOptions}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      placeholder="Choisissez le modèle"
                      id="model"
                    />
                  )}
                  name="model"
                />

                {errors["model"]?.message && (
                  <p className="error">
                    {errors["model"] &&
                      (
                        errors["model"] as {
                          message: string;
                        }
                      ).message}
                  </p>
                )}
              </div>
            )}

            {carburantsInnerOptions && (
              <div className="form-control-wrapper">
                <label className="form-label" htmlFor="fuel">
                  Carburant
                </label>

                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      {...register("fuel")}
                      className="form-control form-control-select"
                      options={carburantsInnerOptions}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      placeholder="Choisissez le carbrant"
                      id="fuel"
                    />
                  )}
                  name="fuel"
                />

                {errors["fuel"]?.message && (
                  <p className="error">
                    {errors["fuel"] &&
                      (
                        errors["fuel"] as {
                          message: string;
                        }
                      ).message}
                  </p>
                )}
              </div>
            )}
            {gearBoxInnerOptions && (
              <div className="form-control-wrapper">
                <label className="form-label" htmlFor="gearbox">
                  Boîte de vitesse
                </label>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      {...register("gearbox")}
                      className="form-control form-control-select"
                      options={gearBoxInnerOptions}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                  name="gearbox"
                />

                {errors["gearbox"]?.message && (
                  <p className="error">
                    {errors["gearbox"] &&
                      (errors["gearbox"] as { message: string }).message}
                  </p>
                )}
              </div>
            )}
            <div className="form-control-wrapper">
              <label className="form-label" htmlFor="mileage">
                Kilométrage max
              </label>
              <input
                className="form-control"
                placeholder="Kilométrage"
                {...register("mileage")}
              />
              {errors["mileage"]?.message && (
                <p className="error">
                  {errors["mileage"] &&
                    (errors["mileage"] as { message: string }).message}
                </p>
              )}
            </div>
            <div className="form-control-wrapper">
              <label className="form-label" htmlFor="prix">
                Prix maximum
              </label>
              <input
                className="form-control"
                placeholder="9060€"
                {...register("prix")}
              />
              {errors["prix"]?.message && (
                <p className="error">
                  {errors["prix"] &&
                    (errors["prix"] as { message: string }).message}
                </p>
              )}
            </div>
            {/*
            <div className="form-control-wrapper">
              <label className="form-label" htmlFor="status">
                État de la voiture
              </label>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    {...register("status")}
                    className="form-control form-control-select"
                    options={options}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
                name="status"
              />

              {errors["status"]?.message && (
                <p className="error">
                  {errors["status"] &&
                    (errors["status"] as { message: string }).message}
                </p>
              )}
            </div>
            */}
          </div>
          <div className="form-actions">
            <Button
              text="Rechercher"
              orange={orange}
              onPress={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

function mapStateToProps(state: any) {
  return { state };
}
export default connect(mapStateToProps)(FormBuy);
