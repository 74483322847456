import React, { useEffect, useState } from "react";
import Grid from "./grid";
import Slider from "@mui/material/Slider";
import { Link, useNavigate } from "react-router-dom";
import CardBuy from "./cardBuy";
import Button from "./button";
import { connect } from "react-redux";
import { startLoading, stopLoading } from "../redux/actions/appActions";
import {
  price_format,
  convertDateCetelemFormat,
  convertToNumber,
  convertCarEngineValue,
  sliderLabelText,
  resetPackCheckbox
} from "../shared/helpers";
import Parser from "html-react-parser";
import {
  form_1_1,
  form_1_2,
  form_1_3,
  form_2_1,
  form_2_2,
  form_2_3,
} from "../shared/static";
import axiosInstance from "../shared/axiosInstance";
import ImageGallery from "react-image-gallery";
import JSONSimulationData from "../shared/simulationDefaultData.json";

const blockProductBuy = ({ productData, images, dispatch }: any) => {
  const navigate = useNavigate();

  let durationPrec: string | number = (
      (JSONSimulationData.apport.maxByPercentage / 100) *
      productData.PrixTTC__c
  ).toFixed();
  durationPrec = parseInt(durationPrec);

  const [apportValue, setApportValue] = useState<number | number[]>(
      JSONSimulationData.apport.default
  );
  const [duration, setDuration] = useState<number | number[]>(
      JSONSimulationData.duree.default
  );
  const [km, setKm] = useState<number | number[]>(
      JSONSimulationData.kilometrage.default
  );
  const [activeForm, setActiveForm] = useState<number>(1);
  const [version, setVersion] = useState<number>(1);
  const [otherFormMonthly, setOtherFormMonthly] = useState<number | null>(null);

  const [simulationData, setSimulationData] = useState<any>(null);
  const pop = document.querySelector(".popup-info") as HTMLDivElement;

  const updateOtherPackMonthly = async (
      target?: number,
      scrollToId?: string
  ) => {
    let innerPack: null | string = null;
    if (target) {
      innerPack = target === 1 ? "tranquility" : "security";
    } else {
      innerPack = activeForm === 1 ? "tranquility" : "security";
    }
    dispatch(startLoading());
    try {
      const data = await axiosInstance.post("/api/simulate", {
        amount: Number(productData.PrixTTC__c),
        creditMileAge: km,
        durationBail: duration,
        vehicleEnergy: convertCarEngineValue(productData.Energie__c),
        dateMiseCirc: convertDateCetelemFormat(productData.DateMEC__c),
        vehicleMileAge: convertToNumber(productData.KmCompteur__c),
        deposit: apportValue,
        depositBail: apportValue,
        duration: duration,
        vehiclePower: convertToNumber(productData.PuissanceCH__c),
        vehicleNat: convertToNumber(productData.NATCode__c),
        pack: innerPack,
        version: 1,
      });
      setOtherFormMonthly(
          data.data.list_credit_out["hydra:member"][0]?.monthly_payment
      );
      dispatch(stopLoading());
      if (scrollToId) {
        const element = document.getElementById(scrollToId);
        if (element) {
          console.log("element:", element);
          setTimeout(() => {
            element.scrollIntoView({ behavior: "smooth" });
          }, 0);
        }
      }
    } catch (err) {
      console.log("err:", err);
      dispatch(stopLoading());
      navigate("/error");
    }
  };

  const checkboxChange = (e, target, form: number, ver: number) => {
    const formToReset = form === 1 ? 2 : 1;
    resetPackCheckbox(formToReset);
    setActiveForm(form);
    setVersion(ver);
    let newVer: number | null = null;
    if (version === ver && form === activeForm) {
      newVer = 1;
    } else {
      newVer = ver;
    }
    if (e.target.checked) {
      const inp = document.getElementById(target) as HTMLInputElement;
      if (inp) {
        inp.checked = false;
      }
      setVersion(1);
    }
    setVersion(newVer);
    updateSimulation(form, newVer, "results",e);
  };

  const updateSimulation = async (
      num: number,
      ver?: any,
      scrollToId?: string,
      e?:any
  ) => {
    dispatch(startLoading());
    const innerVer = ver ? ver : version;
    try {
      const data = await axiosInstance.post("/api/simulate", {
        amount: Number(productData.PrixTTC__c),
        creditMileAge: km,
        durationBail: duration,
        vehicleEnergy: convertCarEngineValue(productData.Energie__c),
        dateMiseCirc: convertDateCetelemFormat(productData.DateMEC__c),
        vehicleMileAge: convertToNumber(productData.KmCompteur__c),
        deposit: apportValue,
        depositBail: apportValue,
        duration: duration,
        vehiclePower: convertToNumber(productData.PuissanceCH__c),
        vehicleNat: convertToNumber(productData.NATCode__c),
        pack: num === 1 ? "security" : num === 2 ? "tranquility" : null,
        version: innerVer,
      });
      console.log(data.data.list_credit_out["hydra:member"][0]);
      setSimulationData(data.data.list_credit_out["hydra:member"][0]);
      updateOtherPackMonthly(num, scrollToId);
    } catch (err) {
      dispatch(stopLoading());
      if (pop) {
        pop.classList.add("show");
        setTimeout(function () {
          pop.classList.remove("show");
        }, 10000);
        e.target.click();
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(startLoading());
      try {
        const data = await axiosInstance.post("/api/simulate", {
          amount: Number(productData.PrixTTC__c),
          creditMileAge: km,
          durationBail: duration,
          vehicleEnergy: convertCarEngineValue(productData.Energie__c),
          dateMiseCirc: convertDateCetelemFormat(productData.DateMEC__c),
          vehicleMileAge: convertToNumber(productData.KmCompteur__c),
          deposit: apportValue,
          depositBail: apportValue,
          duration: duration,
          vehiclePower: convertToNumber(productData.PuissanceCH__c),
          vehicleNat: convertToNumber(productData.NATCode__c),
          pack: "security",
          version: 1,
        });
        console.log(data.data.list_credit_out["hydra:member"][0]);
        setSimulationData(data.data.list_credit_out["hydra:member"][0]);
        updateOtherPackMonthly();
      } catch (err) {
        dispatch(stopLoading());
        if (pop) {
          pop.classList.add("show");
          setTimeout(function () {
            pop.classList.remove("show");
          }, 10000);

        }
      }
    };
    fetchData();
  }, []);
  const handleActiveForm = async (num) => {
    setActiveForm(num);
    setVersion(1);
    resetPackCheckbox();
    updateSimulation(num, 1);
  };
console.log(simulationData, "simulation data");
  return (
      <Grid container className="block-product">
        <Grid sm={12}>
          <div className="head"></div>
          <Grid
              container
              maxWidth="lg"
              sx={{
                sm: {
                  px: 0,
                },
              }}
          >
            <Grid
                sm={12}
                sx={{
                  mt: {
                    md: 7,
                    sm: 4,
                  },
                  mb: {
                    md: 6,
                    sm: 4,
                  },
                }}
            >
              <Link to=".." onClick={() => navigate(-1)} className="link-back">
                &lt; Retour
              </Link>
            </Grid>
            <Grid md={6} sm={12}>
              {images && images.length > 0 && (
                  <div className="product">
                    <div className="left-side">
                      <ImageGallery items={images} />
                    </div>
                  </div>
              )}
            </Grid>
            <Grid
                className="right-side"
                md={6}
                sm={12}
                sx={{
                  display: {
                    md: "block",
                  },
                  px: {
                    md: 4,
                    sm: 0,
                  },
                  my: {
                    md: 0,
                    sm: 0,
                  },
                }}
            >
              <div className="product-details">
                <CardBuy
                    icon="auto"
                    type="product"
                    title="Informations"
                    description="du véhicule"
                />
                <div className="product-details-content">
                  <div className="car-content car-content-buy">
                    <div className="top">
                      <p className="name">{productData.Marque__c+" "+productData.Modele__c}</p>
                      {productData.Version__c ? <p className="description">{productData.Version__c} </p> : ""}
                    </div>
                    <div className="bottom">
                      <div className="list-specs">
                        <ul>
                          <li>
                            <span className="key">Kilomètres :</span>
                            <span className="value">
                            {productData.KmCompteur__c}km
                          </span>
                          </li>
                          <li>
                            <span className="key">Type d’essence :</span>
                            <span className="value">
                            {productData.Energie__c}
                          </span>
                          </li>
                          <li>
                            <span className="key">Boitier du véhicule :</span>
                            <span className="value">
                            {productData.TypeBoite__c}
                          </span>
                          </li>
                        </ul>
                      </div>
                      <div className="right">
                        {productData.PrixTTC__c && (
                            <p className="price">
                              {price_format(productData.PrixTTC__c)}
                              <span className="coin">€</span>
                            </p>
                        )}
                        <div className="location">
                          <p>Montant location à partir de </p>
                          <p>
                            {productData.LoyerMensuel__c || "--- "}€/mois
                            <div className="tooltip">
                              <div className="icon">i</div>
                              <div className="details">
                                Un crédit vous engage et doit être remboursé.
                                Vérifiez vos capacités de remboursement avant de
                                vous engager
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid md={12} sm={12}>
              <div className="block-simulation">
                <div className="title">
                  <h4>Je personnalise mon financement</h4>
                  <div className="block-simulation-body">
                    <div className="slides">
                      <div className="slider">
                        <p className="label">Apport</p>
                        <div className="slider-container">
                          <Slider
                              getAriaLabel={() => "Range"}
                              value={apportValue}
                              min={JSONSimulationData.apport.min}
                              max={durationPrec}
                              step={JSONSimulationData.apport.step}
                              onChange={(e: Event, v: number | number[]) =>
                                  setApportValue(v)
                              }
                              size="small"
                              valueLabelDisplay="on"
                              valueLabelFormat={sliderLabelText}
                          />
                          <div className="values">
                          <span className="min">
                            {JSONSimulationData.apport.min} €
                          </span>
                            <span className="max">{durationPrec} €</span>
                          </div>
                        </div>
                      </div>
                      <div className="slider">
                        <p className="label">Durée</p>
                        <div className="slider-container">
                          <Slider
                              getAriaLabel={() => "Range"}
                              value={duration}
                              min={JSONSimulationData.duree.min}
                              max={JSONSimulationData.duree.max}
                              step={JSONSimulationData.duree.step}
                              onChange={(e: Event, v: number | number[]) =>
                                  setDuration(v)
                              }
                              size="small"
                              valueLabelDisplay="on"
                              valueLabelFormat={(v) => sliderLabelText(v, "mois")}
                          />
                          <div className="values">
                          <span className="min">
                            {JSONSimulationData.duree.min} mois
                          </span>
                            <span className="max">
                            {JSONSimulationData.duree.max} mois
                          </span>
                          </div>
                        </div>
                      </div>
                      <div className="slider">
                        <p className="label">Kilométrage</p>
                        <div className="slider-container">
                          <Slider
                              getAriaLabel={() => "Range"}
                              value={km}
                              min={JSONSimulationData.kilometrage.min}
                              max={JSONSimulationData.kilometrage.max}
                              defaultValue={JSONSimulationData.kilometrage.default}
                              step={1000}
                              onChange={(e: Event, v: number | number[]) =>
                                  setKm(v)
                              }
                              size="small"
                              valueLabelDisplay="on"
                              valueLabelFormat={(v) => sliderLabelText(v, "km")}
                          />
                          <div className="values">
                          <span className="min">
                            {sliderLabelText(
                                JSONSimulationData.kilometrage.min,
                                "km"
                            )}
                          </span>
                            <span className="max">
                            {sliderLabelText(
                                JSONSimulationData.kilometrage.max,
                                "km"
                            )}
                          </span>
                          </div>
                        </div>
                      </div>
                      <Button
                          text="Valider"
                          dark
                          onPress={() =>
                              updateSimulation(activeForm, null, "packs")
                          }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid md={12} xs={12}>
              <div className="block-simulation">
                <div className="title">
                  <h4>Je choisis ma formule</h4>
                </div>
                <div className="block-simulation-body">
                  <div className="packs" id="packs">
                    <div className={`pack${activeForm == 1 ? " active" : ""}`}>
                      <div className="top">
                        <h5>Pack Simply Sécurité</h5>
                        <ul>
                          <li>
                            <input
                                type="checkbox"
                                defaultChecked
                                checked
                                id="1-warranty"
                            />
                            <label htmlFor="1-warranty">
                              Garantie Perte Financière
                              <div className="indecator">
                                <div className="icon">i</div>
                                <div className="details">
                                  <b>
                                    Sécurisez ce qui peut arriver dans votre vie
                                    de conducteur et dans celle de votre véhicule
                                    !
                                  </b>
                                  <span>
                                  La Garantie Perte Financière* est une garantie
                                  complète :
                                </span>
                                  <ul>
                                    <li>
                                      <b>Garantie valeur d’achat</b> en cas de vol
                                      ou de destruction totale : remboursement à
                                      100% du prix de vente réactualisé au jour du
                                      sinistre pendant 5 ans, 20% les années
                                      suivantes.
                                    </li>
                                    <li>
                                      <b>Garantie évènements imprévus</b> en cas
                                      de vente anticipée de votre véhicule.
                                    </li>
                                    <li>
                                      <b>Votre fidélité récompensée</b> en cas de
                                      souscription d’une nouvelle Garantie Perte
                                      Financière à l’issue du 1er contrat.
                                    </li>
                                    <span>
                                    *Détails des conditions et limites de
                                    garantie dans la notice d’information,
                                    disponible dans votre point de vente
                                  </span>
                                  </ul>
                                </div>
                              </div>
                            </label>
                          </li>
                          <li>
                            <input
                                type="checkbox"
                                id="1-warranty-extension"
                                onChange={(e) =>
                                    checkboxChange(e, "1-pack-excellence", 1, 2)
                                }
                            />
                            <label htmlFor="1-warranty-extension">
                              Extension de garantie 12 mois
                              <div className="indecator">
                                <div className="icon">i</div>
                                <div className="details">
                                  <b>
                                    Protégez votre véhicule jusqu’à la fin du
                                    financement !{" "}
                                  </b>
                                  <span>
                                  Avec l’Extension de Garantie, vous êtes à
                                  l’abri de dépenses imprévues de votre
                                  véhicule*, votre mobilité est préservée.
                                </span>
                                  <ul>
                                    <li>
                                      Les frais de réparation (pièces et main
                                      d’œuvre) & d&apos;assistance sont pris en
                                      charge en cas de panne mécanique, électrique
                                      et électroniqueou d’énergie (pour les
                                      véhicules électriques).
                                    </li>
                                    <li>
                                      La mise à disposition d’un véhicule de
                                      remplacement en cas de panne.
                                    </li>

                                    <span>
                                    * Détails de l’éligibilité, des conditions
                                    et limites de garantie dans la notice
                                    d’assurance disponible dans votre point de
                                    vente
                                  </span>
                                  </ul>
                                </div>
                              </div>
                            </label>
                          </li>
                          <li>
                            <input
                                type="checkbox"
                                id="1-pack-excellence"
                                onChange={(e) =>
                                    checkboxChange(e, "1-warranty-extension", 1, 3)
                                }
                            />
                            <label htmlFor="1-pack-excellence">
                              Pack Excellence
                              <div className="indecator">
                                <div className="icon">i</div>
                                <div className="details">
                                  <b>
                                    Maîtrisez votre budget et roulez l’esprit
                                    libre !
                                  </b>
                                  <span>
                                  Pour l’entretien de votre véhicule et en cas
                                  de panne mécanique, le Pack Excellence prend
                                  le relais !
                                </span>
                                  <ul>
                                    <li>
                                      <b>Entretien* :</b> Assurez votre
                                      tranquillité avec le contrat de maintenance
                                      !Vous bénéficiez des révisions selon les
                                      préconisations du constructeur (vidange,
                                      mises à niveau, filtres...). Vous êtes
                                      assuré du remplacement des pièces d’usure**
                                      (plaquettes, disques de frein, courroies,
                                      ampoules). Vous bénéficiez de la prise en
                                      charge du coût du 1er contrôle technique
                                    </li>
                                    <li>
                                      <b>
                                        Extension de garantie* : Assurez votre
                                        sécurité{" "}
                                      </b>
                                      en cas de panne et protégez votre véhicule
                                      jusqu’à la fin du financement avec
                                      l’Extension de Garantie incluse dans le Pack
                                      Excellence !
                                    </li>
                                    <span>
                                    *Détails de l’éligibilité, des conditions et
                                    limites de garanties détaillées dans la
                                    notice du produit disponible sur simple
                                    demande.
                                  </span>
                                    <span>
                                    ** Les pièces d’usure sont listées dans la
                                    notice d’assurance, sous réserve de leur
                                    usure avérée.
                                  </span>
                                  </ul>
                                </div>
                              </div>
                            </label>
                          </li>
                        </ul>
                      </div>
                      <div className="bottom">
                        <div className="price">
                          <p>
                          <span>
                            {activeForm === 1
                                ? simulationData?.monthly_payment.toFixed(2)
                                : otherFormMonthly?.toFixed(2)}
                            €
                          </span>
                            /mois
                          </p>
                          <div className="tooltip">
                            <div className="icon">i</div>
                            <div className="details">
                              Un crédit vous engage et doit être remboursé.
                              Vérifiez vos capacités de remboursement avant de
                              vous engager
                            </div>
                          </div>
                        </div>
                        <Button
                            outlined={activeForm == 2 ? true : false}
                            dark={activeForm == 1 ? true : false}
                            text="Choisir cette formule"
                            onPress={() => handleActiveForm(1)}
                        />
                      </div>
                    </div>
                    <div className={`pack${activeForm == 2 ? " active" : ""}`}>
                      <div className="top">
                        <h5>Pack Simply Tranquillité</h5>
                        <ul>
                          <li>
                            <input
                                type="checkbox"
                                defaultChecked
                                checked
                                id="2-warranty"
                            />
                            <label htmlFor="2-warranty">
                              Garantie Perte Financière
                              <div className="indecator">
                                <div className="icon">i</div>
                                <div className="details">
                                  <b>
                                    Sécurisez ce qui peut arriver dans votre vie
                                    de conducteur et dans celle de votre véhicule
                                    !
                                  </b>
                                  <span>
                                  La Garantie Perte Financière* est une garantie
                                  complète :
                                </span>
                                  <ul>
                                    <li>
                                      <b>Garantie valeur d’achat</b> en cas de vol
                                      ou de destruction totale : remboursement à
                                      100% du prix de vente réactualisé au jour du
                                      sinistre pendant 5 ans, 20% les années
                                      suivantes.
                                    </li>
                                    <li>
                                      <b>Garantie évènements imprévus</b> en cas
                                      de vente anticipée de votre véhicule.
                                    </li>
                                    <li>
                                      <b>Votre fidélité récompensée</b> en cas de
                                      souscription d’une nouvelle Garantie Perte
                                      Financière à l’issue du 1er contrat.
                                    </li>
                                    <span>
                                    *Détails des conditions et limites de
                                    garantie dans la notice d’information,
                                    disponible dans votre point de vente
                                  </span>
                                  </ul>
                                </div>
                              </div>
                            </label>
                          </li>
                          <li>
                            <input
                                type="checkbox"
                                defaultChecked
                                checked
                                id="2-warranty-emprunteur"
                            />
                            <label htmlFor="2-warranty-emprunteur">
                              Garantie Emprunteur
                              <div className="indecator">
                                <div className="icon">i</div>
                                <div className="details">
                                  <b>
                                    Sécurisez-vous et menez votre projet auto en
                                    toute sérénité !
                                  </b>
                                  <span>
                                  Avec l’Assurance Emprunteur (Protexxio Santé*)
                                  :
                                </span>
                                  <ul>
                                    <li>
                                      Votre famille n’aura pas à rembourser le
                                      solde du financement de votre véhicule en
                                      cas de Décès ou de Perte Totale et
                                      Irréversible d&apos;Autonomie.
                                    </li>
                                    <li>
                                      Le remboursement de votre mensualité ne sera
                                      pas un souci jusqu’à 18 mois, en cas
                                      d’Incapacité Temporaire Totale de travail.
                                    </li>
                                    <span>
                                    *Détails des conditions et limites
                                    détaillées dans la notice d’information
                                    disponible sur simple demande
                                  </span>
                                  </ul>
                                </div>
                              </div>
                            </label>
                          </li>
                          <li>
                            <input
                                type="checkbox"
                                id="2-warranty-extension"
                                onChange={(e) =>
                                    checkboxChange(e, "2-pack-excellence", 2, 2)
                                }
                            />
                            <label htmlFor="2-warranty-extension">
                              Extension de garantie 12 mois
                              <div className="indecator">
                                <div className="icon">i</div>
                                <div className="details">
                                  <b>
                                    Protégez votre véhicule jusqu’à la fin du
                                    financement !{" "}
                                  </b>
                                  <span>
                                  Avec l’Extension de Garantie, vous êtes à
                                  l’abri de dépenses imprévues de votre
                                  véhicule*, votre mobilité est préservée.
                                </span>
                                  <ul>
                                    <li>
                                      Les frais de réparation (pièces et main
                                      d’œuvre) & d&apos;assistance sont pris en
                                      charge en cas de panne mécanique, électrique
                                      et électroniqueou d’énergie (pour les
                                      véhicules électriques).
                                    </li>
                                    <li>
                                      La mise à disposition d’un véhicule de
                                      remplacement en cas de panne.
                                    </li>

                                    <span>
                                    * Détails de l’éligibilité, des conditions
                                    et limites de garantie dans la notice
                                    d’assurance disponible dans votre point de
                                    vente
                                  </span>
                                  </ul>
                                </div>
                              </div>
                            </label>
                          </li>
                          <li>
                            <input
                                type="checkbox"
                                id="2-pack-excellence"
                                onChange={(e) =>
                                    checkboxChange(e, "2-warranty-extension", 2, 3)
                                }
                            />
                            <label htmlFor="2-pack-excellence">
                              Pack Excellence
                              <div className="indecator">
                                <div className="icon">i</div>
                                <div className="details">
                                  <b>
                                    Maîtrisez votre budget et roulez l’esprit
                                    libre !
                                  </b>
                                  <span>
                                  Pour l’entretien de votre véhicule et en cas
                                  de panne mécanique, le Pack Excellence prend
                                  le relais !
                                </span>
                                  <ul>
                                    <li>
                                      <b>Entretien* :</b> Assurez votre
                                      tranquillité avec le contrat de maintenance
                                      !Vous bénéficiez des révisions selon les
                                      préconisations du constructeur (vidange,
                                      mises à niveau, filtres...). Vous êtes
                                      assuré du remplacement des pièces d’usure**
                                      (plaquettes, disques de frein, courroies,
                                      ampoules). Vous bénéficiez de la prise en
                                      charge du coût du 1er contrôle technique
                                    </li>
                                    <li>
                                      <b>
                                        Extension de garantie* : Assurez votre
                                        sécurité{" "}
                                      </b>
                                      en cas de panne et protégez votre véhicule
                                      jusqu’à la fin du financement avec
                                      l’Extension de Garantie incluse dans le Pack
                                      Excellence !
                                    </li>
                                    <span>
                                    *Détails de l’éligibilité, des conditions et
                                    limites de garanties détaillées dans la
                                    notice du produit disponible sur simple
                                    demande.
                                  </span>
                                    <span>
                                    ** Les pièces d’usure sont listées dans la
                                    notice d’assurance, sous réserve de leur
                                    usure avérée.
                                  </span>
                                  </ul>
                                </div>
                              </div>
                            </label>
                          </li>
                        </ul>
                      </div>
                      <div className="bottom">
                        <div className="price">
                          <p>
                          <span>
                            {activeForm === 2
                                ? simulationData?.monthly_payment.toFixed(2)
                                : otherFormMonthly?.toFixed(2)}
                            €
                          </span>
                            /mois
                            <div className="tooltip">
                              <div className="icon">i</div>
                              <div className="details">
                                Un crédit vous engage et doit être remboursé.
                                Vérifiez vos capacités de remboursement avant de
                                vous engager
                              </div>
                            </div>
                          </p>
                        </div>
                        <Button
                            outlined={activeForm == 1 ? true : false}
                            dark={activeForm == 2 ? true : false}
                            onPress={() => handleActiveForm(2)}
                            text="Choisir cette formule"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid md={12} sm={12}>
              <div className="block-simulation" id="results">
                <div className="title">
                  <h4>Solution de financement : Location avec Option d’Achat</h4>
                </div>
                <Grid
                    container
                    className="solution"
                    columnSpacing={8}
                    sx={{
                      py: {
                        md: 6,
                      },
                      my: {
                        xs: 2,
                      },
                    }}
                >
                  <Grid
                      md={6}
                      sm={12}
                      sx={{
                        my: {
                          md: 0,
                          xs: 6,
                        },
                      }}
                  >
                    <ul>
                      <li>
                        <span className="key">Pour un véhicule de : </span>
                        <span>{simulationData?.amount?.toFixed(2)}€</span>
                      </li>
                      <li>
                        <span className="key">Apport :</span>
                        <span>{simulationData?.deposit?.toFixed(2)}€</span>
                      </li>
                      <li>
                        <span className="key">Montant emprunté : </span>
                        <span>{simulationData?.overdraft?.toFixed(2)}€</span>
                      </li>

                      <li>
                        <b>
                          <span className="key">Nombre de loyers :</span>
                          <span>{simulationData?.duration?.toFixed(2)}</span>
                        </b>
                      </li>
                      <li>
                        <span className="key">Premier loyer :</span>
                        <span>{simulationData?.first_rent?.toFixed(2)}€</span>
                      </li>
                      <li>
                        <span className="key">Loyers suivants :</span>
                        <span>
                        {simulationData?.monthly_payment?.toFixed(2)}€
                      </span>
                      </li>
                      <li>
                        <b>
                        <span className="key">
                          Montant total dû avec option d’achat finale :
                        </span>
                          <span>{simulationData?.total_amount?.toFixed(2)}€</span>
                        </b>
                      </li>
                      <li>
                        <b>
                        <span className="key">
                          Montant total dû sans option d’achat finale :
                        </span>
                          <span>
                          {(
                              simulationData?.total_amount -
                              simulationData?.vr_amount
                          ).toFixed(2)}
                            €
                        </span>
                        </b>
                      </li>
                    </ul>
                    <div className="actions">
                      <Button
                          text="Confirmer et continuer"
                          dark
                          to={{
                            path: "/formulaire-financement",
                            data: {
                              state: {
                                productData,
                                images,
                                simulationData,
                                activeForm,
                                version,
                              },
                            },
                          }}
                      />
                    </div>
                  </Grid>
                  <Grid md={6} sm={12}>
                    <div className="details">
                      <h5>
                        Un crédit vous engage et doit être remboursé. Vérifiez vos
                        capacités de remboursement avant de vous engager.
                      </h5>
                      {simulationData && (
                          <p>
                            {activeForm === 1 &&
                                version === 1 &&
                                Parser(form_1_1(simulationData))}
                            {activeForm === 1 &&
                                version === 2 &&
                                Parser(form_1_2(simulationData))}
                            {activeForm === 1 &&
                                version === 3 &&
                                Parser(form_1_3(simulationData))}
                            {activeForm === 2 &&
                                version === 1 &&
                                Parser(form_2_1(simulationData))}
                            {activeForm === 2 &&
                                version === 2 &&
                                Parser(form_2_2(simulationData))}
                            {activeForm === 2 &&
                                version === 3 &&
                                Parser(form_2_3(simulationData))}
                          </p>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  );
};

function mapStateToProps(state: any) {
  return { state };
}
export default connect(mapStateToProps)(blockProductBuy);