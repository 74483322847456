// isDev
const isDev = process.env.REACT_APP_ENV === "development";

// reset pack checkbox
const resetPackCheckbox = (formToReset?: number) => {
  if (formToReset) {
    const packElements = document.querySelectorAll(".pack");
    const checkboxesInPack = packElements[formToReset - 1].querySelectorAll(
      'input[type="checkbox"]'
    );
    checkboxesInPack.forEach((checkbox: any) => {
      checkbox.checked = false;
    });
  } else {
    const packElements = document.querySelectorAll(
      '.pack input[type="checkbox"]'
    );
    packElements.forEach((checkbox: any) => {
      checkbox.checked = false;
    });
  }
};

function isEmptyString(str: string | number): boolean {
  return str === "";
}

// Parse and Filter KmCompteur
const parseAndFilterKmCompteur = (
  objects: any[],
  maxMiles: string | number
) => {
  if (isEmptyString(maxMiles)) {
    return objects;
  }
  maxMiles = Number(maxMiles);
  objects.forEach((obj) => {
    if (obj.KmCompteur__c != null && obj.KmCompteur__c !== undefined) {
      obj.KmCompteur__c = Number(obj.KmCompteur__c);
    }
  });
  objects = objects.filter((obj) => obj.KmCompteur__c <= maxMiles);
  return objects;
};

// Parse and Filter PrixTTC
const parseAndFilterPrixTTC = (objects: any[], maxPrix: string | number) => {
  if (isEmptyString(maxPrix)) {
    return objects;
  }

  maxPrix = Number(maxPrix);
  objects.forEach((obj) => {
    if (obj.PrixTTC__c != null && obj.PrixTTC__c !== undefined) {
      obj.PrixTTC__c = Number(obj.PrixTTC__c);
    }
  });
  objects = objects.filter((obj) => obj.PrixTTC__c <= maxPrix);
  return objects;
};

// convert car engine
const convertCarEngineValue = (energie: string) => {
  switch (energie) {
    case "GO":
      return "DE";
    case "ES":
      return "ES";
    case "EL":
      return "EL";
    case "EH":
      return "ES";
    default:
      return energie;
  }
};

// string to number
const convertToNumber = (value: any) => {
  if (typeof value === "string") {
    return Number(value);
  } else {
    return value;
  }
};
// date cetelem format
const convertDateCetelemFormat = (dateString: string) => {
  const parts = dateString.split("/");
  const day = parts[0].padStart(2, "0");
  const month = parts[1].padStart(2, "0");
  const year = parts[2];

  return day + month + year;
};
// check two objects
const haveSameKeysAndValues = (arr1: any[], arr2: any[]) => {
  // Check if the length of arr1 and arr2 is the same
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Iterate over each object in arr1 and compare with arr2
  for (let i = 0; i < arr1.length; i++) {
    if (!isObjectEqual(arr1[i], arr2[i])) {
      return false;
    }
  }

  return true;
};

function isObjectEqual(obj1: any, obj2: any): boolean {
  // Get the keys of obj1 and obj2
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Check if all keys in obj1 have the same values in obj2
  for (const key of keys1) {
    if (
      !Object.prototype.hasOwnProperty.call(obj2, key) ||
      obj1[key] !== obj2[key]
    ) {
      return false;
    }
  }

  return true;
}

// Check if the "tokenData" cookie exists
const checkTokenDataCookie = () => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith("tokenData=")) {
      return true;
    }
  }
  return false;
};

// Retrieve the bearer token from the cookie
const getBearerTokenFromCookie = () => {
  const name = "tokenData=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieParts = decodedCookie.split(";");
  for (let i = 0; i < cookieParts.length; i++) {
    const cookiePart = cookieParts[i].trim();
    if (cookiePart.indexOf(name) === 0) {
      let token = cookiePart.substring(name.length);
      token = token.slice(1, -1);
      return token;
    }
  }
  return null;
};

const radioUncheck = (getValues: any, setValue: any): void => {
  const radios = document.querySelectorAll('[type="radio"]');
  radios.forEach((radio) => {
    function unCheck(e: any) {
      const values = getValues();
      if (values[e.target.name] === e.target.value) {
        e.target.checked = false;
        setValue(e.target.name, null);
      }
    }
    radio.removeEventListener("click", unCheck);
    radio.addEventListener("click", unCheck);
  });
};

function sliderLabelText(value: number | string, label?: unknown) {
  const stringValue = value.toString().replace(/,/g, "");

  let formattedValue = stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  formattedValue = `${formattedValue} ${label ? label : "€"}`;

  return formattedValue;
}

const numberPlateFormat = () => {
  const immInput = document.getElementById(
    "imm-input"
  ) as HTMLInputElement | null;
  if (immInput) {
    immInput.addEventListener("keypress", function (event) {
      if (this.value.length >= 9) {
        event.preventDefault();
      }
    });

    immInput.addEventListener("keyup", function (event) {
      const val: string = this.value;
      const keyCode = event.keyCode || event.charCode;
      const length = val.length;
      this.value = val.toUpperCase();
      if ((length == 2 || length == 6) && !(keyCode == 8 || keyCode == 46)) {
        this.value = val + "-";
      }
    });
  }
};

const price_format = (number: number | string) => {
  const priceStr = String(number);
  const parts = priceStr.split(".");
  let formattedStr = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  if (parts.length > 1) {
    formattedStr += "." + parts[1];
  }

  return formattedStr;
};

export {
  radioUncheck,
  sliderLabelText,
  numberPlateFormat,
  checkTokenDataCookie,
  getBearerTokenFromCookie,
  haveSameKeysAndValues,
  price_format,
  convertDateCetelemFormat,
  convertToNumber,
  convertCarEngineValue,
  isDev,
  parseAndFilterKmCompteur,
  parseAndFilterPrixTTC,
  resetPackCheckbox,
};
