import React, { ReactNode } from "react";
import Grid from "./grid";

interface Props {
  children: ReactNode;
  light?: boolean;
  orange?: boolean;
}

export default function Head({ children, light, orange }: Props) {
  return (
    <div
      className={`head${light ? " head-light" : ""}${
        orange ? " head-orange" : ""
      }`}
    >
      <Grid container maxWidth="lg">
        <div className="wrapper">
          <h1>{children}</h1>
        </div>
      </Grid>
    </div>
  );
}
