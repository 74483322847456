import {combineReducers} from 'redux';
import user from './userReducer';
import app from './appReducer';

const rootReducer = combineReducers({
  user,
  app,
});

export default rootReducer;
