import React from "react";
import PageWrapper from "../components/PageWrapper";
import Head from "../components/head";
import Agents from "../components/Agents";
import CardsReassurance from "../components/cardsReassurance";
import { Helmet } from "react-helmet";

export default function GuidPage() {
  return (
    <div className="page-guid">
        <Helmet>
            <title>Chasseurs d’Autos - Découvrez l’équipe et la mission de Chasseurs d’Autos</title>
            <meta
                name="description"
                content="Chasseurs d’Autos est le premier réseau d’indépendants adossé à de véritables professionnels de l’automobile. Découvrez notre équipe et notre mission"
            />
            <link
                rel="canonical"
                href="https://chasseursdautos.com/qui-sommes-nous"
            />
        </Helmet>
      <PageWrapper>
        <Head>
          <b>Qui sommes-nous ?</b>
        </Head>
        <Agents />
        <CardsReassurance />
      </PageWrapper>
    </div>
  );
}
