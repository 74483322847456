import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { ScrollRestoration } from "react-router-dom";

function App({ children }: any) {
  return (
    <>
      <ScrollRestoration/>
      <Header />
      {children}
      <Footer />
    </>
  );
}

export default App;
