import React, { useState } from "react";
import Grid from "./grid";
import { NavLink, Link } from "react-router-dom";
import Icon from "../components/Icon";
const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <header>
      <Grid container className="container" maxWidth="lg" spacing={2}>
        <Grid item xs={3} className="logo-wrapper">
          <Link to="/">
            <img className="logo" src="/images/logo.png" alt="Logo" />
          </Link>
        </Grid>
        <Grid
          item
          xs={9}
          className={`${showMenu ? "mobile-show" : ""} nav-list`}
        >
          <ul>
            <button
              className="menu-toggler"
              onClick={() => setShowMenu(!showMenu)}
            >
              <Icon
                icon={showMenu ? "Close" : "Menu"}
                size={showMenu ? 25 : 28}
              />
            </button>
            <li>
              <NavLink to="/">Accueil</NavLink>
            </li>
            <li>
              <NavLink to="/acheter">J’achète mon véhicule</NavLink>
            </li>
            <li>
              <NavLink to="/vendre">Je vends mon véhicule</NavLink>
            </li>
            <li>
              <NavLink to="/qui-sommes-nous">Qui sommes nous&nbsp;?</NavLink>
            </li>
            <li>
              <NavLink to="/comment-ca-marche">Comment ça marche&nbsp;?</NavLink>
            </li>
          </ul>
        </Grid>
      </Grid>
    </header>
  );
};
export default Header;
