import React from "react";
import Icon from "./Icon";
import { useNavigate } from "react-router-dom";

const Button = ({
  text,
  icon,
  dark,
  to,
  url,
  outlined,
  onPress,
  submit,
  orange,
}: {
  text?: string;
  icon?: string;
  dark: boolean;
  green: boolean;
  to?: any;
  url?: string;
  onPress?: () => void;
  outlined: boolean;
  submit?: boolean;
  orange?: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <button
      type={submit ? "submit" : "button"}
      className={`btn${outlined ? " btn-outlined" : icon ? " btn-icon" : ""}${
        orange ? " btn-orange" : ""
      }`}
      style={{
        backgroundColor: dark
          ? "#2c4395"
          : outlined
          ? "transparent"
          : "#7879F1",
        border: `1px solid ${outlined ? "#2c4395" : "transparent"}`,
        color: outlined ? "#2c4395" : "white",
      }}
      onClick={() => {
        if (onPress) {
          onPress();
        } else if (to) {
          if (to.data) {
            navigate(to.path, to.data);
          } else {
            navigate(to);
          }
        } else if (url) {
          window.open(url, "_blank");
        }
      }}
    >
      {text && (
        <div className="text-wrapper">
          <span>{text}</span>
        </div>
      )}
      {icon && (
        <div className="icon-wrapper">
          <Icon icon={icon} size={28} />
        </div>
      )}
      {!icon && (
        <div
          className="icon-wrapper"
          style={{
            borderColor: outlined ? "#2c4395" : "inhiret",
          }}
        >
          <Icon icon="ArrowRight" size={15} />
        </div>
      )}
    </button>
  );
};

Button.defaultProps = {
  dark: false,
  outlined: false,
  onPress: null,
  green: false,
};

export default Button;
