import React from "react";
import { useLocation } from "react-router";
import PageWrapper from "../components/PageWrapper";
import BlockProductBuy from "../components/blockProductBuy";

export default function ProductPage() {
  const { state } = useLocation();
  const productData = state?.productData;
  const images = state?.images;
  if (!productData) return <h1>No data</h1>;

  return (
    <div className="page-Product">
      <PageWrapper>
        <BlockProductBuy productData={productData} images={images} />
          <div className="popup-info">
              <p>Ce véhicule n’est pas éligible au pack Excellence</p>
          </div>
      </PageWrapper>
    </div>
  );
}
