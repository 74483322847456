import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import Icon from "./Icon";

function PaginatedItems({
  itemsPerPage,
  items,
  currentItemsRender = () => {return},
}: {
  itemsPerPage: number;
  items: Array<any>;
  currentItemsRender?: (array) => void;
}) {
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);
  currentItemsRender(currentItems)

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  if(pageCount == 0 || pageCount == 1) return null;

  return (
    <div className="pagination-wrapper">
      <ReactPaginate
        nextLabel={<Icon icon="ArrowRight" size={13} />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel={<Icon icon="ArrowLeft" size={13} />}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        //  renderOnZeroPageCount={null}
      />
    </div>
  );
}
export default PaginatedItems;
