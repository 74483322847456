import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Grid from "./grid";
import Button from "./button";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as yup from "yup";
import { radioUncheck, numberPlateFormat, isDev } from "../shared/helpers";
import ReCAPTCHA from "react-google-recaptcha";

import { yupResolver } from "@hookform/resolvers/yup";

const recaptchaToken = process.env.REACT_APP_RECAPTCHA_TOKEN;

yup.setLocale({
  mixed: {
    required: "Ce champ est obligatoire",
  },
});

const fieldDate = isDev ? "00N7Z0000087ieK" : "00N68000003PFn9";
const fieldHoure = isDev ? "00N7Z00000892cF" : "00N68000003PFni";
const fieldImmatriculation = isDev ? "00N7Z0000087ieJ" : "00N68000003PFnn";
const fieldMarque = isDev ? "00N7Z0000087ieA" : "00N68000003PFnT";
const fieldModele = isDev ? "00N7Z0000087ieL" : "00N68000003PFnY";
const fieldKilometrage = isDev ? "00N7Z0000087ieH" : "00N68000003PFnO";
const fieldBudget = isDev ? "00N7Z0000087ieD" : "00N68000003PFns";
const fieldStatus = isDev ? "00N7Z0000087ieC" : "00N68000003PFny";
const fieldControle = isDev ? "00N7Z0000087ieM" : "00N68000003PFn4";
const fieldNote = isDev ? "00N7Z0000087ieN" : "00N68000003PFmu";
const fieldTerms = isDev ? "00N7Z000008yt8W" : "00N68000003PFnx";

const schema = yup.object().shape({
  first_name: yup.string().max(40).required(),
  last_name: yup.string().max(80).required(),
  phone: yup.number().typeError("Doit être un nombre").required(),
  city: yup.string().max(40).required(),
  email: yup
      .string()
      .email("S'il vous plaît, mettez une adresse email valide")
      .max(80)
      .required(),
  [fieldDate]: yup.string().required(), // Date
  [fieldHoure]: yup.string().required(), // Houre
  [fieldImmatriculation]: yup
      .string()
      .matches(/^[A-Z]{2}-\d{3}-[A-Z]{2}$/, "Ce champ n'est pas valide")
      .required(), // Immatruculation
  [fieldMarque]: yup.string().max(100).required(), // Marque
  [fieldModele]: yup.string().max(100).required(), // Modele
  [fieldKilometrage]: yup.string().required(), // Kilométrage
  [fieldBudget]: yup.string().required(), // Budget
  [fieldStatus]: yup.string().max(100).required(), // Status
  controle: yup.string().required(), // Controle
  note: yup.string().required(), // Note
  [fieldTerms]: yup
      .string()
      .oneOf(["1"], "Vous devez accepter les Termes")
      .required("Vous devez accepter les Termes"), // CandT
});

const statusOptions = [
  { value: "bon état", label: "Bon état" },
  { value: "état standard", label: "État standard" },
  { value: "mauvais état", label: "Mauvais état" },
];

const houres = [
  { value: "8h-10h", label: "8h-10h" },
  { value: "10h-12h", label: "10h-12h" },
  { value: "12h-14h", label: "12h-14h" },
  { value: "14h-16h", label: "14h-16h" },
  { value: "16h-18h", label: "16h-18h" },
  { value: "18h-20h", label: "18h-20h" },
];

export default function FormBuy() {
  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  console.log("errors:", errors);

  const location = useLocation();

  const formData = location.state?.formData;

  const [captcha, setCaptcha] = useState({ isNotRobot: false, error: false });

  const watchCity = watch("city");

  function onChange(value) {
    if (value) {
      setCaptcha({ isNotRobot: true, error: false });
    }
  }

  const onSubmit = (data: any, e: any) => {
    if (!captcha.isNotRobot) {
      setCaptcha({ isNotRobot: false, error: true });
      return;
    }

    const el1 = document.getElementsByName(
        fieldControle
    )[0] as HTMLInputElement;
    const el2 = document.getElementsByName(fieldNote)[0] as HTMLInputElement;

    if (data.controle == "1") {
      el1.checked = true;
    } else {
      el1.checked = false;
    }
    if (data.note == "1") {
      el2.checked = true;
    } else {
      el2.checked = false;
    }
    setTimeout(() => {
      e.target.submit();
    }, 0);
  };

  useEffect(() => {
    if (watchCity) {
      setValue("city", watchCity.toUpperCase());
    }
  }, [watchCity]);

  useEffect(() => {
    // radio uncheck
    radioUncheck(getValues, setValue);
    const subscription = watch((value, { name, type }) =>
        console.log(value, name, type)
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    numberPlateFormat();

    if (formData && formData.marque) {
      setValue(fieldMarque, formData.marque);
    }

    if (formData && formData.model) {
      setValue(fieldModele, formData.model);
    }
  }, [location?.state?.formData]);

  return (
      <div className="block-form">
        <Grid container maxWidth="lg" columnSpacing={8}>
          <Grid xs={12} md={7} mdOffset={0}>
            <h3>Completer le formulaire</h3>
          </Grid>
          <Grid
              xs={12}
              sx={{
                p: {
                  xs: 0,
                  md: 2,
                },
              }}
          >
            <form
                className="form form-sell-2"
                action={`https://${
                    isDev ? "test" : "webto"
                }.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8`}
                method="POST"
                onSubmit={handleSubmit(onSubmit)}
            >
              <input
                  name={fieldControle}
                  className="hidden"
                  type="checkbox"
                  value="1"
              />
              <input
                  name={fieldNote}
                  className="hidden"
                  type="checkbox"
                  value="1"
              />
              <input
                  type="hidden"
                  name="oid"
                  value={isDev ? "00D7Z0000005J1X" : "00D680000029HFM"}
              />
              <input
                  type="hidden"
                  name="retURL"
                  value={
                    isDev
                        ? "https://chasseurs-auto.lagentx.tech?vendre=true&statut=succes"
                        : "https://chasseursdautos.com?vendre=true&statut=succes"
                  }
              />

              <select id="lead_source" name="lead_source" hidden>
                <option selected value="Formulaire_site_web"></option>
              </select>
              <input
                  id="utm_source" name="00N68000003PFoM" type="hidden" />
              <input
                  id="utm_medium" name="00N68000003PFoH" type="hidden" />
              <input
                  id="utm_term" name="00N68000003PFoR" type="hidden" />
              <input
                  id="utm_campaign" name="00N68000003PFo7" type="hidden" />
              <input
                  id="utm_content" name="00N68000003PFoC" type="hidden" />

              <Grid container maxWidth="lg" className="form-content" spacing={0}>
                <Grid
                    xs={12}
                    md={5}
                    mdOffset={0}
                    sx={{
                      my: 0,
                    }}
                >
                  <p className="form-title">Informations personnelles</p>
                  <Grid
                      container
                      maxWidth="lg"
                      className="form-controllers"
                      spacing={0}
                  >
                    <Grid xs={12} md={5.5} mdOffset={0}>
                      <label className="form-label" htmlFor="first_name">
                        Prénom*
                      </label>
                      <input
                          autoComplete="off"
                          className="form-control"
                          placeholder="Jean"
                          {...register("first_name")}
                      />

                      {errors["first_name"]?.message && (
                          <p className="error">
                            {errors["first_name"] &&
                                (errors["first_name"] as { message: string }).message}
                          </p>
                      )}
                    </Grid>
                    <Grid xs={12} md={5.5} mdOffset={1}>
                      <label className="form-label" htmlFor="last_name">
                        Nom*
                      </label>
                      <input
                          autoComplete="off"
                          className="form-control"
                          placeholder="Dupont"
                          {...register("last_name")}
                      />
                      {errors["last_name"]?.message && (
                          <p className="error">
                            {errors["last_name"] &&
                                (errors["last_name"] as { message: string }).message}
                          </p>
                      )}
                    </Grid>
                    <Grid xs={12} md={5.5} mdOffset={0}>
                      <label className="form-label" htmlFor="phone">
                        Téléphone*
                      </label>
                      <input
                          autoComplete="off"
                          className="form-control"
                          placeholder="+33"
                          {...register("phone")}
                      />
                      {errors["phone"]?.message && (
                          <p className="error">
                            {errors["phone"] &&
                                (errors["phone"] as { message: string }).message}
                          </p>
                      )}
                    </Grid>
                    <Grid xs={12} md={5.5} mdOffset={1}>
                      <label className="form-label" htmlFor="city">
                        Ville*
                      </label>
                      <input
                          autoComplete="off"
                          className="form-control"
                          placeholder="Lille"
                          {...register("city")}
                      />

                      {errors["city"]?.message && (
                          <p className="error">
                            {errors["city"] &&
                                (errors["city"] as { message: string }).message}
                          </p>
                      )}
                    </Grid>
                    <Grid xs={12} md={12}>
                      <label className="form-label" htmlFor="email">
                        Email*
                      </label>
                      <input
                          autoComplete="off"
                          className="form-control"
                          placeholder="contact@gmail.com"
                          {...register("email")}
                      />
                      {errors["email"]?.message && (
                          <p className="error">
                            {errors["email"] &&
                                (errors["email"] as { message: string }).message}
                          </p>
                      )}
                    </Grid>
                    <p className="form-title">Préférence de contact</p>
                    <Grid xs={12} md={12}>
                      <label className="form-label" htmlFor={fieldDate}>
                        Date souhaitée du rendez-vous*
                      </label>
                      <Controller
                          control={control}
                          name={fieldDate}
                          render={({ field }) => (
                              <DatePicker
                                  {...register(fieldDate)}
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="00/00/2023"
                                  className="form-control"
                                  onChange={(date) => field.onChange(date)}
                                  selected={field.value}
                                  minDate={new Date()}
                                  autoComplete="off"
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                              />
                          )}
                      />
                      {errors[fieldDate]?.message && (
                          <p className="error">
                            {errors[fieldDate] &&
                                (errors[fieldDate] as { message: string }).message}
                          </p>
                      )}
                    </Grid>
                    <Grid xs={12} md={12}>
                      <label className="form-label" htmlFor={fieldHoure}>
                        Plage horaire souhaitée*
                      </label>
                      <Controller
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field: { onChange, onBlur, value } }) => (
                              <Select
                                  {...register(fieldHoure)}
                                  className="form-control form-control-select"
                                  options={houres}
                                  onBlur={onBlur}
                                  onChange={(data: any) => {
                                    onChange(data.value);
                                  }}
                                  value={value ? { value, label: value } : null}
                                  placeholder="00h-00h"
                              />
                          )}
                          name={fieldHoure}
                      />

                      {errors[fieldHoure]?.message && (
                          <p className="error">
                            {errors[fieldHoure] &&
                                (errors[fieldHoure] as { message: string }).message}
                          </p>
                      )}
                    </Grid>
                    <Grid
                        xs={12}
                        md={12}
                        className="form-control-wrapper form-control-wrapper-checkbox"
                    >
                      <input
                          type="checkbox"
                          className="form-control"
                          id={fieldTerms}
                          value="1"
                          {...register(fieldTerms)}
                      />
                      <label htmlFor={fieldTerms} className="tandc">
                        Je reconnais que les champs marqués d’un astérisque sont
                        obligatoires. Chasseurs d’Autos traite les données
                        recueillies pour répondre à votre demande de
                        renseignement. Pour en savoir plus sur la gestion de vos
                        données personnelles et pour exercer vos droits,
                        reportez-vous à la politique de confidentialité.
                      </label>
                      {errors[fieldTerms]?.message && (
                          <p className="error">
                            {errors[fieldTerms] &&
                                (errors[fieldTerms] as { message: string }).message}
                          </p>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={12} md={5} mdOffset={2}>
                  <p className="form-title">Le véhicule</p>
                  <Grid
                      container
                      maxWidth="lg"
                      className="form-controllers"
                      spacing={0}
                  >
                    <Grid xs={12} md={12}>
                      <label
                          className="form-label"
                          htmlFor={fieldImmatriculation}
                      >
                        Plaque d’immatriculation*
                      </label>
                      <input
                          autoComplete="off"
                          id="imm-input"
                          className="form-control"
                          placeholder="AA-000-AA"
                          {...register(fieldImmatriculation)}
                      />

                      {errors[fieldImmatriculation]?.message && (
                          <p className="error">
                            {errors[fieldImmatriculation] &&
                                (errors[fieldImmatriculation] as { message: string })
                                    .message}
                          </p>
                      )}
                    </Grid>
                    <Grid xs={12} md={5.5} mdOffset={0}>
                      <label className="form-label" htmlFor={fieldMarque}>
                        Marque*
                      </label>
                      <Controller
                          name={fieldMarque}
                          control={control}
                          render={({ field }) => (
                              <input
                                  {...register(fieldMarque)}
                                  autoComplete="off"
                                  className="form-control"
                                  placeholder="Marque"
                                  onChange={field.onChange}
                                  value={field.value}
                              />
                          )}
                      />
                      {errors[fieldMarque]?.message && (
                          <p className="error">
                            {errors[fieldMarque] &&
                                (errors[fieldMarque] as { message: string }).message}
                          </p>
                      )}
                    </Grid>
                    <Grid xs={12} md={5.5} mdOffset={1}>
                      <label className="form-label" htmlFor={fieldModele}>
                        Modèle*
                      </label>
                      <Controller
                          name={fieldModele}
                          control={control}
                          render={({ field }) => (
                              <input
                                  {...register(fieldModele)}
                                  autoComplete="off"
                                  className="form-control"
                                  placeholder="Modèle"
                                  onChange={field.onChange}
                                  value={field.value}
                              />
                          )}
                      />
                      {errors[fieldModele]?.message && (
                          <p className="error">
                            {errors[fieldModele] &&
                                (errors[fieldModele] as { message: string }).message}
                          </p>
                      )}
                    </Grid>

                    <Grid xs={12} md={5.5} mdOffset={0}>
                      <label className="form-label" htmlFor={fieldKilometrage}>
                        Kilométrage*
                      </label>
                      <input
                          autoComplete="off"
                          className="form-control"
                          placeholder="Kilométrage"
                          {...register(fieldKilometrage)}
                      />
                      {errors[fieldKilometrage]?.message && (
                          <p className="error">
                            {errors[fieldKilometrage] &&
                                (errors[fieldKilometrage] as { message: string })
                                    .message}
                          </p>
                      )}
                    </Grid>
                    <Grid xs={12} md={5.5} mdOffset={1}>
                      <label className="form-label" htmlFor={fieldBudget}>
                        Prix de vente souhaité*
                      </label>
                      <input
                          autoComplete="off"
                          className="form-control"
                          placeholder="9060€"
                          {...register(fieldBudget)}
                      />
                      {errors[fieldBudget]?.message && (
                          <p className="error">
                            {errors[fieldBudget] &&
                                (errors[fieldBudget] as { message: string }).message}
                          </p>
                      )}
                    </Grid>
                    <Grid xs={12} md={12}>
                      <label className="form-label" htmlFor={fieldStatus}>
                        État de la voiture*
                      </label>
                      <Controller
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field: { onChange, onBlur, value } }) => (
                              <Select
                                  {...register(fieldStatus)}
                                  className="form-control form-control-select"
                                  options={statusOptions}
                                  onBlur={onBlur}
                                  onChange={(data: any) => {
                                    onChange(data.value);
                                  }}
                                  value={value ? { value, label: value } : null}
                              />
                          )}
                          name={fieldStatus}
                      />

                      {errors[fieldStatus]?.message && (
                          <p className="error">
                            {errors[fieldStatus] &&
                                (errors[fieldStatus] as { message: string }).message}
                          </p>
                      )}
                    </Grid>

                    <Grid
                        xs={12}
                        md={12}
                        mdOffset={0}
                        sx={{
                          my: {
                            xs: 1,
                          },
                        }}
                    >
                      <label className="form-label">
                        Contrôle technique à jour ?*
                      </label>
                      <br />
                      <input
                          type="radio"
                          value="1"
                          id="q1_oui"
                          {...register("controle")}
                      />
                      <label htmlFor="q1_oui">Oui</label>
                      <input
                          type="radio"
                          id="q1_no"
                          value="0"
                          {...register("controle")}
                      />
                      <label htmlFor="q1_no">Non</label>
                      {errors["controle"]?.message && (
                          <p className="error">
                            {errors["controle"] &&
                                (errors["controle"] as { message: string }).message}
                          </p>
                      )}
                    </Grid>
                    <Grid
                        xs={12}
                        md={12}
                        mdOffset={0}
                        sx={{
                          my: {
                            xs: 1,
                          },
                        }}
                    >
                      <label className="form-label">
                        Ce véhicule dispose-il d’un carnet d’entretien à jour ?*
                      </label>
                      <br />
                      <input
                          type="radio"
                          value="1"
                          id="q2_oui"
                          {...register("note")}
                      />
                      <label htmlFor="q2_oui">Oui</label>
                      <input
                          type="radio"
                          id="q2_no"
                          value="0"
                          {...register("note")}
                      />
                      <label htmlFor="q2_no">Non</label>
                      {errors["note"]?.message && (
                          <p className="error">
                            {errors["note"] &&
                                (errors["note"] as { message: string }).message}
                          </p>
                      )}
                    </Grid>

                    {recaptchaToken && (
                        <div className="form-captch">
                          <ReCAPTCHA
                              className="form-captch"
                              sitekey={recaptchaToken}
                              onChange={onChange}
                          />

                          {captcha.error && (
                              <p className="error error-captch">
                                Le captcha est invalide. Veuillez réessayer.
                              </p>
                          )}
                        </div>
                    )}

                    <Button text="Envoyer" submit dark />
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </div>
  );
}