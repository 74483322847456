import React from "react";
import Icon from "./Icon";

interface Props {
  icon?: string;
  title?: string;
  details?: string;
  type?: string;
}

export default function Card({ icon, title, details, type }: Props) {
  return (
    <div className={`card${type ? ` card-${type}` : ""}`}>
      {icon && (
        <div className="icon-wrapper">
          <Icon icon={icon} size={100} />
        </div>
      )}
      <h3>{title}</h3>
      <p>{details}</p>
    </div>
  );
}
