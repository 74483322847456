import React from "react";
import PageWrapper from "../components/PageWrapper";
import Head from "../components/head";
import FormSell from "../components/formSell";
import BlockImage from "../components/blockImage";
import Button from "../components/button";
import CardsReassurance from "../components/cardsReassurance";
import Grid from "../components/grid";
import { Helmet } from "react-helmet";

export default function SellPage() {
  return (
    <div className="page-sell">
      <Helmet>
        <title>Chasseurs d’Autos - Vendez votre voiture rapidement et évitez les tracas avec Chasseurs d’Autos</title>
        <meta
            name="description"
            content="Vendez votre voiture rapidement et facilement avec Chasseurs d’Autos. Nos experts s’occupent de tout, de la prise de photo à la gestion de la relation avec les acheteurs"
        />
        <link
            rel="canonical"
            href="https://chasseursdautos.com/vendre"
        />
      </Helmet>
      <PageWrapper>
        <Head>Je vends mon véhicule avec Chasseurs d’Autos</Head>
        <Grid
          container
          sx={{
            px: {
              xs: 2,
            },
          }}
        >
          <Grid>
            <BlockImage top>
              <h2>Vendez votre auto à coup sûr !</h2>
              <div className="block-body">
                <p>
                  Envie d’être accompagné dans la vente de votre véhicule et
                  ainsi éviter les tracas avec les particuliers? Conviez la
                  vente de votre véhicule à Chasseurs d’Autos, le premier réseau
                  d’indépendants adossé à de véritables professionnels de
                  l’automobile. Nos experts s’occupent de tout
                </p>
                <br />
                <br />
                <br />
              </div>
              <div className="actions">
                <Button
                  text="Des questions ? Voir les différentes étapes"
                  outlined
                />
              </div>
            </BlockImage>
          </Grid>
        </Grid>
        <FormSell />
        <CardsReassurance />
      </PageWrapper>
    </div>
  );
}
