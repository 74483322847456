import React from "react";
import Grid from "./grid";
import Card from "./card";

export default function cardsReassurance() {
  return (
    <div className="cards-reassurance">
      <Grid
        container
        rowSpacing={{
          md: 42,
          xs: 4,
        }}
        columnSpacing={{
          md: 4,
          xs: 4,
        }}
        sx={{
          px: 2,
          py: {
            md: 12,
            xs: 4,
          },
        }}
        maxWidth="lg"
      >
        <Grid xs={12} md={4}>
          <Card
            type="reassurance"
            icon="alert"
            title="DIFFUSION"
            details="Confier la vente de son véhicule à un chasseur d’auto vous assure une diffusion massive et gratuite auprès de  millions d’intentionnistes sur les principaux sites à forte audience automobile."
          />
        </Grid>
        <Grid xs={12} md={4}>
          <Card
            type="reassurance"
            icon="defence"
            title="GARANTIE & FINANCEMENT"
            details="Les acheteurs de véhicules recherchent de la sécurité et des garanties. Grâce à Chasseurs d’Autos, votre véhicule bénéficie d’une garantie 6 mois minimum*. De plus, nous proposons des solutions de financement. Deux atouts déterminant pour la vente de véhicules entre particuliers."
          />
        </Grid>
        <Grid xs={12} md={4}>
          <Card
            type="reassurance"
            icon="car"
            title="MISE EN VALEUR"
            details="Mettre en valeur son véhicule est 
primordial pour le vendre rapidement. 
Bénéficiez du réseau partenaire de Chasseurs d’Autos pour obtenir des prestations esthétiques ou mécaniques pour que votre véhicule soit au top !"
          />
        </Grid>
      </Grid>
    </div>
  );
}
